import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography, CardContent, Button, CircularProgress } from "@mui/material";
import { red } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { notifyError, UserContext, UserDataContext } from "../App";
import { saveGeneratedItem, votePoem } from "../firebase/firebaseCalls";
import './justify.css'
import { toast } from "react-toastify";

export const GeneratedItem = ({ key, item, displayVotes, displayMode, loading, canSave = false }) => {
    const { user } = useContext(UserContext);
    const { userData, setUserData } = useContext(UserDataContext);
    // const votes = userData.votes || {};
    const setVotes = (newVotes) => setUserData({ ...userData, votes: newVotes });

    const [upvoteCount, setUpvoteCount] = useState(0);
    const [downvoteCount, setDownvoteCount] = useState(0);

    // const [userVote, setUserVote] = useState(
    //     !displayVotes ? null : poem.id in votes ? votes[poem.id] : null
    // );

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);


    useEffect(() => {
        if (item.img_url !== '') setSaveButtonDisabled(false);
        if (item.img_url === '') setSaveButtonDisabled(true);
    }, [item.img_url]);

    useEffect(() => {
        console.log('item', item)
    }, [])
    console.log('item url', item.img_url)


    const handleSaveQrcode = async () => {
        console.log('called save')
        setSaveButtonDisabled(true);
        if (item.img_url !== '') {
            const res = await saveGeneratedItem(item)
                .then((res) => {
                    console.log('res', res);
                    toast.success('QR Code saved successfully');
                })
                .catch((error) => {
                    console.error('Error saving QR Code:', error);
                    toast.error('Error saving QR Code');
                });


        }
    };



    return (


        <Card key={key}
            elevation={10}
            sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: 1,
                border: '1px solid #e0e0e0',
                margin: 'auto',
                boxShadow: '0 2px 4px 1px gray',

            }}
        >
            <CardContent>
                {/* letter spacing */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                    <Typography variant="h6" gutterBottom fontFamily={'Roboto Mono'} letterSpacing={1} >
                        <strong>{item.config.global.prompt}</strong>
                    </Typography>

                </Box>
                {loading && (
                    <Box display="flex" justifyContent="center" marginTop={2}>
                        <CircularProgress />
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>

                    {item.img_url !== '' &&
                        <img src={item.img_url} alt="image" style={{ width: "100%", maxWidth: '512px', height: 'auto' }} />
                    }
                </Box>

                {canSave && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                        <Button fullWidth type='submit' variant="contained" color="primary" onClick={handleSaveQrcode} disabled={saveButtonDisabled} >Save to Public Archives</Button>
                    </Box>
                )}

            </CardContent>
        </Card>
    );
};
