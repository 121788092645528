import { DE, ES, FR, IT, PT, US } from 'country-flag-icons/react/3x2'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox, Label, Lock } from '@mui/icons-material';
import { ArrowDownward, ArrowUpward, ThumbDown, ThumbUp } from '@mui/icons-material';
import { Box, FormControl, Card, Paper, CardContent, IconButton, Container, Grid, Select, MenuItem, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Slider, FormLabel, TextareaAutosize, FormControlLabel, Checkbox, Autocomplete, InputLabel } from '@mui/material';
import { useContext, useState, useEffect, useRef } from 'react';
import { CurrentGenerationContext, notifyError, notifySuccess, UserContext } from '../App';
import { callGenerateItem } from '../firebase/firebaseCalls';
import { randomTopicList } from './generatedExemples';
import { logEvent } from 'firebase/analytics';
import { analytics, db } from '../firebase/firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { MoreParamsTabs } from './MoreParamsTabs';
import { PanelGenParamsSubmitButton } from './PanelGenParamsSubmitButton';

const CustomInputField = ({ inputRef, ...rest }) => {

    return (
        <TextareaAutosize
            {...rest}
            inputRef={inputRef}
            maxRows={10}
            minRows={1}
        />
    );
}





export function PanelGenParams({ setSessionHistory, isAnimationEnabled, setIsAnimationEnabled, loading, setLoading, availableConfigsAndModels }) {
    const { user, setUser } = useContext(UserContext);
    const { currentGeneration, setCurrentGeneration } = useContext(CurrentGenerationContext);



    const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);

    const getRandomTopic = () => {
        return { title: 'my prompt', prompt: 'my prompt', img_url: 'https://cdn.openart.ai/stable_diffusion/3954196738cf8aea63f36e49db4be38eebbd6caf_2000x2000.webp' }
    };


    const [topicProgress, setTopicProgress] = useState(0);
    const topicProgressRef = useRef(topicProgress);
    const firstRandom = getRandomTopic();
    const [randomTopic, setRandomTopic] = useState(firstRandom.title);
    const randomTopicRef = useRef(randomTopic);

    const stopRef = useRef(false); // Add this ref to control when to stop

    const handleStop2 = () => {
        stopRef.current = true;
        setGenerateButtonDisabled(false);
    };

    const [isUserInteracted, setIsUserInteracted] = useState(false);

    const stopInterval = (interval) => {
        clearInterval(interval);
    };

    const handleUserInteraction = () => {
        setIsUserInteracted(true);
    };

    const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
    const [isGenerateButton, setIsGenerateButton] = useState(true);

    const [restartAnimation, setRestartAnimation] = useState(0);

    const [isFirstAnimation, setIsFirstAnimation] = useState(true);

    const [shouldCheckURL, setShouldCheckURL] = useState(false);

    const [isURLValid, setIsURLValid] = useState(false);


    const [selectedConfig, setSelectedConfig] = useState("default_auto");
    console.log("availableConfigsAndModels", availableConfigsAndModels);
    console.log("config", currentGeneration.config);


    // when selectedConfig changes, update currentGeneration config
    useEffect(() => {
        // if selectedConfig.keys.len > 0
        if (Object.keys(availableConfigsAndModels.configs).length === 0) return;
        setCurrentGeneration({ ...currentGeneration, config: availableConfigsAndModels.configs[selectedConfig] })
    }, [availableConfigsAndModels, selectedConfig]);

    console.log('currentGeneration', currentGeneration);






    useEffect(() => {
        if (!isAnimationEnabled || isUserInteracted) return;
        console.log(currentGeneration)
        topicProgressRef.current = 0;
        let randomExemple = null;
        randomExemple = getRandomTopic();

        const updateTopicInterval = setInterval(() => {
            if (topicProgressRef.current < randomExemple.prompt.length) {
                topicProgressRef.current = topicProgressRef.current + 1;
                setCurrentGeneration((prevGeneratedPoem) => {
                    return {
                        ...prevGeneratedPoem,
                        config: { ...prevGeneratedPoem.config, global: { ...prevGeneratedPoem.config.global, prompt: randomExemple.prompt.substring(0, topicProgressRef.current) } },
                    }
                })
            }
            else if (topicProgressRef.current === randomExemple.prompt.length) {
                setCurrentGeneration((prevGeneratedPoem) => {
                    return {
                        ...prevGeneratedPoem,
                        img_url: randomExemple.img_url,
                    }
                })
                topicProgressRef.current = topicProgressRef.current + 1;
            }

            else {
                stopInterval(updateTopicInterval);
                setTimeout(() => {

                    const eraseTopicInterval = setInterval(() => {

                        if (topicProgressRef.current === randomExemple.prompt.length + 1) {
                            topicProgressRef.current = topicProgressRef.current - 1;
                            setCurrentGeneration((prevGeneratedPoem) => {
                                return {
                                    ...prevGeneratedPoem,
                                    img_url: '',
                                }
                            });
                        }

                        else if (topicProgressRef.current > 0) {

                            topicProgressRef.current = topicProgressRef.current - 1;
                            setCurrentGeneration((prevGeneratedPoem) => {
                                return {
                                    ...prevGeneratedPoem,
                                    config: { ...prevGeneratedPoem.config, global: { ...prevGeneratedPoem.config.global, prompt: randomExemple.prompt.substring(0, topicProgressRef.current) } },

                                }
                            });
                        } else {
                            stopInterval(eraseTopicInterval);
                            setTimeout(() => {
                                if (isUserInteracted) return;
                                setRestartAnimation(restartAnimation + 1);


                            }, 1000);
                        }
                    }, 5);
                }, 3000);
            }
        }, 20);

        return () => {
            stopInterval(updateTopicInterval);
        };
    }, [isUserInteracted, randomTopic, isAnimationEnabled, restartAnimation]);



    const handleChangeTopic = (event) => {
        setErrorTopicField(event.target.value === '')
        setCurrentGeneration({ ...currentGeneration, config: { ...currentGeneration.config, global: { ...currentGeneration.config.global, prompt: event.target.value } } });
    };

    const [errorTopicField, setErrorTopicField] = useState(false);


    const handleGenerateItem = async () => {
        console.log('handleGenerateItem');
        setIsUserInteracted(true);
        setIsAnimationEnabled(false);
        topicProgressRef.current = 0;

        setLoading(true);
        setGenerateButtonDisabled(true);
        if (currentGeneration.config.global.prompt === '') {
            setErrorTopicField(true);
            setLoading(false);
            setGenerateButtonDisabled(false);
            return;

        }

        logEvent(analytics, 'generate_item', {
            ...currentGeneration
        });


        console.log('currentGeneration', currentGeneration);
        await callGenerateItem(currentGeneration.config)

            .then((response) => {

                // wait for doc change and update the img_url
                const firestoreId = response.firestoreId;
                const docRef = doc(db, "Pending", firestoreId);
                onSnapshot(docRef, (doc) => {
                    console.log("snapshot")
                    if (doc.exists() && doc.data().img_url) {
                        setCurrentGeneration((prevGeneratedPoem) => {
                            console.log("set current generation", doc.data().img_url)
                            return {
                                ...prevGeneratedPoem,
                                img_url: doc.data().img_url,
                            }
                        });
                        setSessionHistory((prevSessionHistory) => {
                            return [...prevSessionHistory, { ...currentGeneration, img_url: doc.data().img_url }]
                        });

                        setGenerateButtonDisabled(false);
                        setLoading(false);
                        const element_generated_poem = document.getElementById('generatedItemBlock');
                        element_generated_poem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                });
            }
            )
            .catch((error) => {
                notifyError('Error: ' + error.message);
                setLoading(false);
                setGenerateButtonDisabled(false);
            });
    };


    return <Paper
        elevation={10}
        sx={{
            padding: 2,
            borderRadius: 2,
        }}
    >
        <Grid container spacing={2}>
            <Grid item sm={3} xs={12} order={{ sm: 2 }} >


                <Box marginTop={2} display="flex" justifyContent="center">
                    {randomPromptCheckbox(isAnimationEnabled, setIsUserInteracted, setIsAnimationEnabled, isFieldsDisabled)}
                </Box>
            </Grid>
            {/* Select config */}
            <Grid item sm={3} xs={12} order={{ sm: 1 }} >
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Config</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedConfig}
                        label="Config"
                        onChange={(e) => {
                            setSelectedConfig(e.target.value);
                        }
                        }
                    >
                        {Object.keys(availableConfigsAndModels.configs).map((config) => {
                            return <MenuItem value={config}>{config}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>



            <Grid item sm={9} xs={12} order={{ sm: 1 }}>
                {promptField(currentGeneration, handleChangeTopic, errorTopicField, setIsAnimationEnabled, setGenerateButtonDisabled, topicProgressRef, isFieldsDisabled)}

            </Grid>

            <Grid item sm={9} xs={12} order={{ sm: 1 }}>
                {qrcodeTextField(currentGeneration, setCurrentGeneration, errorTopicField, setIsAnimationEnabled, setGenerateButtonDisabled, topicProgressRef, isFieldsDisabled)}

            </Grid>



            {/* beautiful collapse */}
            <Grid item xs={12} order={{ sm: 9 }}>
                <Accordion
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        borderRadius: 2,
                        '& .MuiAccordionSummary-root': {
                            padding: 0,
                            '& .MuiAccordionSummary-content': {
                                margin: 0,
                            },
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                margin: 0,
                            },
                        }}
                    >
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
                                Advanced options
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* config global params: */}
                        {/* mode: txt2img (or img2img)
                        prompt: string
                        negative_prompt: string
                        model_name_or_path: one of the available sd_models
                        steps: int slider 0 to 100
                        scheduler_name: one of the available schedulers: DDIM, Euler, Euler a, LMS, DPM2 Karras, DPM2 a Karras, Heun, DDPM, UniPC, PNDM, DEI, DPM++ SDE, DPM++ 2S a, DPM++ 2M, DPM++ SDE Karras, DPM++ 2S a Karras, DPM++ 2M Karras
                        cfg_scale: int slider 0 to 30
                        width: [512, 640, 768, 896, 1024 ]
                        height: [512, 640, 768, 896, 1024 ]
                        seed: int field
                        */}
                        {Object.keys(availableConfigsAndModels.configs).length > 0 &&

                            <MoreParamsTabs availableConfigsAndModels={availableConfigsAndModels} />
                        }

                    </AccordionDetails>
                </Accordion>
            </Grid>


            <br />
            <Grid item xs={12} order={{ sm: 9 }}>

                <PanelGenParamsSubmitButton
                    generateItem={handleGenerateItem}
                />

            </Grid>

        </Grid>
    </Paper>;


}



function randomPromptCheckbox(isAnimationEnabled, setIsUserInteracted, setIsAnimationEnabled, isFieldsDisabled) {
    return <FormControlLabel
        control={<Checkbox
            checked={isAnimationEnabled}
            onChange={(e) => {
                setIsUserInteracted(!e.target.checked);
                setIsAnimationEnabled(e.target.checked);
            }}
            disabled={isFieldsDisabled} />}
        label="Random prompt animation" />;
}

function promptField(currentGeneration, handleChangeTopic, errorTopicField, setIsAnimationEnabled, setGenerateButtonDisabled, topicProgressRef, isFieldsDisabled) {
    return <FormControl fullWidth>
        <TextField
            label="Prompt"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={currentGeneration.config.global.prompt}
            onChange={handleChangeTopic}
            // sx={{ marginBottom: 2 }}
            error={errorTopicField}
            helperText={'Description of what you want to generate. '}
            id="prompt"
            inputProps={{
                maxLength: 500,
                inputComponent: CustomInputField,
                style: { fontSize: 30, lineHeight: 1.2, fontFamily: 'Roboto Mono' },
                minRows: 1,
                maxRows: 3,
            }}

            onFocus={() => {
                setIsAnimationEnabled(false);
                setGenerateButtonDisabled(false);
                topicProgressRef.current = 0;
            }}
            disabled={isFieldsDisabled} />
    </FormControl>;
}

function qrcodeTextField(currentGeneration, setCurrentGeneration, errorTopicField, setIsAnimationEnabled, setGenerateButtonDisabled, topicProgressRef, isFieldsDisabled) {
    return <FormControl fullWidth>
        <TextField
            label="QR code data"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={currentGeneration.config.qrcode.text}
            onChange={(e) => setCurrentGeneration({ ...currentGeneration, config: { ...currentGeneration.config, qrcode: { ...currentGeneration.config.qrcode, text: e.target.value } } })}
            // sx={{ marginBottom: 2 }}
            error={errorTopicField}
            helperText={'Link or text to be encoded in the QR code. '}
            id="qrcodeText"
            inputProps={{
                maxLength: 500,
                inputComponent: CustomInputField,
                style: { fontSize: 30, lineHeight: 1.2, fontFamily: 'Roboto Mono' },
                minRows: 1,
                maxRows: 3,
            }}

            onFocus={() => {
                setIsAnimationEnabled(false);
                setGenerateButtonDisabled(false);
                topicProgressRef.current = 0;
            }}
            disabled={isFieldsDisabled} />
    </FormControl>;
}

