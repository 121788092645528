import { useContext, useEffect } from "react";
import { AppStateContext } from "../App";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { getWebuiStatus } from "../firebase/firebaseCalls";

export function PanelGenParamsSubmitButton({ generateItem }) {

    const { appState, setAppState } = useContext(AppStateContext);

    console.log('appState.webuiStatus', appState.webuiStatus)

    // set webui status at startup
    useEffect(() => {
        getWebuiStatus().then((result) => {
            console.log('result', result)
            setAppState({ ...appState, webuiStatus: 'online' })
        }).catch((err) => {
            console.log(err);
            setAppState({ ...appState, webuiStatus: 'offline' })
        });
    }, []);



    // fetch https://us-central1-kollai-common.cloudfunctions.net/sdwebui_common to start the gpu backend
    // use POST method and content-type: application/json and {} args
    // use no-cors mode
    function handleStartGPUBackend() {
        console.log('called handleStartGPUBackend')
        fetch('https://us-central1-kollai-common.cloudfunctions.net/sdwebui_common')
            .then(
                (result) => {
                    console.log('result', result)
                    setAppState({ ...appState, webuiStatus: 'pending' })
                }
            )
            .catch((error) => {
                console.error('Error starting GPU backend:', error);
                setAppState({ ...appState, webuiStatus: 'offline' })
            }
            )
    }

    // if appState.webuiStatus === pending, check webui status every 5 sec firebase functin getWebuiStatus until it is online
    useEffect(() => {
        const interval = setInterval(() => {
            if (appState.webuiStatus === 'pending') {
                console.log('checking webui status')
                setAppState({ ...appState, webuiStatus: 'checking' })
                getWebuiStatus().then((result) => {
                    console.log('result', result)
                    if (result.status === 'online') {
                        setAppState({ ...appState, webuiStatus: 'online' })
                    }
                    else {
                        setAppState({ ...appState, webuiStatus: 'pending' })
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [appState.webuiStatus]);




    // if appState.webuiStatus === 'pending' show loading in the disabled button
    if (appState.webuiStatus === 'pending') {
        return (
            <Box display="flex" justifyContent="center" marginBottom={1} marginTop={0}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={generateItem}
                    disabled
                >
                    Loading ...
                </Button>
            </Box>
        );
    }

    if (appState.webuiStatus === 'checking') {
        return (
            <Box display="flex" justifyContent="center" marginBottom={1} marginTop={0}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled
                >
                    <CircularProgress />
                </Button>
            </Box>

        );
    }







    if (appState.webuiStatus === 'offline') {
        return (
            <Box display="flex" justifyContent="center" marginBottom={1} marginTop={0}>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleStartGPUBackend}
                >
                    Start GPU Backend ! (required once before generating)
                </Button>
            </Box>
        );
    }

    // else: appState.webuiStatus === 'online' 

    return (
        <Box display="flex" justifyContent="center" marginBottom={1} marginTop={0}>

            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={generateItem}
            // disabled={generateButtonDisabled}
            >

                <Typography variant="h6" gutterBottom >
                    Generate!
                </Typography>
            </Button>
        </Box>
    );
}