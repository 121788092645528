import { useContext, useState, useRef, useEffect } from 'react';
import { Box, FormControl, Card, Paper, CardContent, IconButton, Container, Grid, Select, MenuItem, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { votePoem } from '../firebase/firebaseCalls';
import { AppStateContext, CurrentGenerationContext, notifyError, UserContext } from '../App';
import { GeneratedItem } from '../components/GeneratedItem';
import { PanelGenParams } from '../components/PanelGenParams';
import { saveGeneratedItem, rewritePoemWithCritics } from '../firebase/firebaseCalls';
import { toast } from 'react-toastify';
import { ScrollDownFab } from '../components/ScrollDownFab';
import { analytics } from '../firebase/firebase';
import { logEvent } from 'firebase/analytics';
import { InsertEmoticonSharp } from '@mui/icons-material';





const HomePage = () => {
    const { user, setUser } = useContext(UserContext)
    const { currentGeneration, setCurrentGeneration } = useContext(CurrentGenerationContext);
    const [sessionHistory, setSessionHistory] = useState([]);

    // const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const [loading, setLoading] = useState(false);

    const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);
    const [availableConfigsAndModels, setAvailableConfigsAndModels] = useState({
        configs: {
            default_auto: {
                global: {
                    prompt: "",
                    mode: "txt2img", // default value
                    negative_prompt: "", // default value
                    model_name_or_path: "v1-5-pruned-emaonly.safetensors [6ce0161689]", // default value
                    steps: 0, // default value
                    scheduler_name: "DDIM", // default value
                    cfg_scale: 1, // default value
                    width: 512, // default value
                    height: 512, // default value
                    seed: 0, // default value
                },
                controlnet_units: {},
                qrcode: { text: "https://koll.ai", error_correction_level: "high", border: 4, box_size: 10 }
            },
        },

        sd_models: ["no_sd_model"],
        cn_models: ["no_cn_model"]
    });

    const { appState, setAppState } = useContext(AppStateContext);

    const BASE_URL = "https://us-central1-qr-art-41f77.cloudfunctions.net"
    const ROUTE_GET_AVAILABLE_EVERYTHING = "/function_get_available_everything"

    //analytics
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Home',
            page_location: window.location.href,
            page_path: '/',
        });
    }, []);


    const controler = new AbortController();
    const timeoutId = setTimeout(() => controler.abort(), 5000);
    useEffect(() => {
        if (appState.webuiStatus === 'online') {
            fetch(BASE_URL + ROUTE_GET_AVAILABLE_EVERYTHING, { signal: controler.signal })
                .then(res => res.json())
                .then(
                    (result) => {
                        setAvailableConfigsAndModels(result)
                        setAppState({ ...appState, webuiStatus: 'online' })

                    },
                    (error) => {
                        notifyError("you need to start the vm")
                        setAppState({ ...appState, webuiStatus: 'offline' })
                    }
                )
                .finally(() => clearTimeout(timeoutId));
        }
    }, [appState.webuiStatus])





    return (
        <Container maxWidth="md" sx={{ marginTop: 2 }}>
            <ScrollDownFab />
            {!user && (
                // center typography
                <Box display="flex" justifyContent="center">
                    <Typography variant="subtitle2" gutterBottom color={'error'} >
                        {/* insert here non user message */}
                    </Typography>
                </Box>
            )}
            <Box display="flex" justifyContent="center">
                <Typography variant="subtitle2" gutterBottom color={'error'} >
                    {/* <b>New! April 4:</b> New languages: <b>French Picard/Ch'ti</b> and <b>French Canadian</b> (works better with GPT-4 model) */}
                    {/* Insert here news */}
                </Typography>
            </Box>

            <PanelGenParams
                setSessionHistory={setSessionHistory}
                isAnimationEnabled={isAnimationEnabled} setIsAnimationEnabled={setIsAnimationEnabled}
                loading={loading} setLoading={setLoading}
                availableConfigsAndModels={availableConfigsAndModels}
            />

            <br />
            <br />


            <div id="generatedItemBlock">
                <Grid Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} md={12} alignItems="center" sx={{ width: '100%' }}>


                        <GeneratedItem id="generatedItem"
                            item={currentGeneration}
                            key={'index'}
                            userVote={null}
                            displayVotes={false}
                            loading={loading}
                            canSave={true}

                        />


                    </Grid>
                    {/* Center vertically */}



                </Grid>
            </div>


            <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 1 }}>
                History {sessionHistory.length > 0 && !user && "(Will be erased when you leave the page)"}
            </Typography>
            <Typography>
                Click on an element of the grid to load the corresponding item back.
                {sessionHistory.length === 0 && (
                    <span> You have not generated any item yet.</span>
                )}

            </Typography>

            {/* 3. Create a grid to display the history of generated poems. */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {sessionHistory.map((item, index) => (
                    <Grid item key={index} xs={12} sm={6}>
                        <GeneratedItem
                            id={`historyPoem-${index}`}
                            item={item}
                            key={index}
                            userVote={null}
                            displayVotes={false}
                            canSave={false}
                            sx={{
                                cursor: 'pointer',
                                border: item.id === currentGeneration.id ? '2px solid #3f51b5' : 'none',
                            }}
                        />
                    </Grid>
                ))}
            </Grid>


        </Container >
    );
};

export default HomePage;
