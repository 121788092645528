import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import GlobalConfigPanel from "./ConfigPanelGlobal";
import ControlnetsConfigPanel from "./ConfigPanelControlnet";
import QrcodeConfigPanel from "./ConfigPanelQrcode";

export const MoreParamsTabs = ({ availableConfigsAndModels }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Global" />
                <Tab label="Controlnets" />
                <Tab label="Base QR code" />
            </Tabs>
            {activeTab === 0 && <GlobalConfigPanel value={0} index={0} availableConfigsAndModels={availableConfigsAndModels} />}
            {activeTab === 1 && <ControlnetsConfigPanel value={1} index={1} availableConfigsAndModels={availableConfigsAndModels} />}
            {activeTab === 2 && <QrcodeConfigPanel value={2} index={2} availableConfigsAndModels={availableConfigsAndModels} />}
        </Box>




    );
}