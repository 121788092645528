import { collection, query, orderBy, limit, startAfter, getDocs, getDoc, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db } from './firebase';
import { app } from './firebase';
import { functions } from './firebase';

export const getItemsFromArchives = async (lastVisible) => {
    const itemRef = collection(db, 'Archives');
    const archiveQuery = query(
        itemRef,
        orderBy('timestamp', 'desc'),
        limit(10),
        ...(lastVisible ? [startAfter(lastVisible)] : [])
    );

    const querySnapshot = await getDocs(archiveQuery);
    const archives = querySnapshot.docs.map((doc) =>
        ({ id: doc.id, ...doc.data() })
    );

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { archives: archives, lastVisible: newLastVisible };
};

export const getUserPoems = async (uid, lastVisible) => {
    // query the firestore database for the user's poems: User/uid/History
    const userPoemsRef = collection(db, 'Users', uid, 'History');
    const userPoemsQuery = query(
        userPoemsRef,
        orderBy('timestamp', 'desc'),
        limit(10),
        ...(lastVisible ? [startAfter(lastVisible)] : [])
    );

    const querySnapshot = await getDocs(userPoemsQuery);
    const poems = querySnapshot.docs.map((doc) =>
        ({ id: doc.id, ...doc.data() })
    );

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { poems, lastVisible: newLastVisible };
}


export const getOwnProfile = async (uid) => {
    // query the firestore database for the user's profile
    const profilRef = doc(db, 'Users', uid)
    return getDoc(profilRef).then((doc) => {
        if (doc.exists()) {
            return doc.data();
        } else {
            console.log('No such document!');
        }
    }
    ).catch((error) => {
        console.log('Error getting document:', error);
    }
    );


};


export const votePoem = async (poemId, voteType) => {
    const votePoemFunction = httpsCallable(functions, 'votePoem');
    await votePoemFunction({ poemId, voteType });
};

export const rewritePoemWithCritics = async (generatedPoem, critics, poemSize, topic, language, acrosticWord, acrosticLine, model) => {
    console.log('calling rewritePoemWithCritics', critics, poemSize, topic, language, acrosticWord, acrosticLine, model)
    const rewritePoemWithCriticsFunction = httpsCallable(functions, 'rewritePoemWithCritics');
    const response = await rewritePoemWithCriticsFunction({ generatedPoem, critics, poemSize, topic, language, acrosticWord, acrosticLine, model });
    const ret = response.data;
    console.log(ret);
    return ret;
};


export const callGenerateItem = async (config) => {
    console.log('calling generateItem', config)
    const pubsubpublishFunction = httpsCallable(functions, 'pubsubpublish');
    const response = await pubsubpublishFunction({ config }).catch((error) => {
        console.error('Error generating document:', error);
    }
    );
    const ret = response.data;
    console.log(ret);
    return ret;
};

export const saveGeneratedItem = async (item) => {
    const saveItem = httpsCallable(functions, 'saveGeneratedItem');
    const response = await saveItem({ item: item });
    const ret = response.data;
    console.log(ret);
    return ret;

}

export const createCheckoutSession = async (packageId) => {
    const createCheckoutSessionFunction = httpsCallable(functions, 'createCheckoutSession');
    const response = await createCheckoutSessionFunction({ packageId });
    const ret = response.data;
    console.log(ret);
    return ret;
}

export const getWebuiStatus = async () => {
    const getWebuiStatusFunction = httpsCallable(functions, 'getWebuiStatus');
    const response = await getWebuiStatusFunction();
    const ret = response.data;
    console.log(ret);
    return ret;
}
