import React, { useContext, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Slider, Grid, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { CurrentGenerationContext } from '../App';


const ControlnetsConfigPanel = ({ availableConfigsAndModels }) => {
    const { currentGeneration, setCurrentGeneration } = useContext(CurrentGenerationContext);
    const schedulers = ['DDIM', 'Euler', 'Euler a', 'LMS', 'DPM2 Karras', 'DPM2 a Karras', 'Heun', 'DDPM', 'UniPC', 'PNDM', 'DEI', 'DPM++ SDE', 'DPM++ 2S a', 'DPM++ 2M', 'DPM++ SDE Karras', 'DPM++ 2S a Karras', 'DPM++ 2M Karras'];
    const sd_models = availableConfigsAndModels.sd_models;
    const modes = ['txt2img', 'img2img'];
    const sizes = [512, 640, 768, 896, 1024];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                global: {
                    ...prevState.config.global,
                    [name]: value
                }
            }
        }));

    };

    const handleSliderChange = (event, newValue) => {
        setCurrentGeneration({
            ...currentGeneration,
            config: {
                ...currentGeneration.config,
                global: {
                    ...currentGeneration.config.global,
                    [event.target.name]: newValue
                }
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                global: globalConfig
            }
        }));
    };
    console.log(currentGeneration.config.global.mode)
    return (
        <div>
            <br />
            {/* table with the columns empty, weight, start, stop  */}
            {/* columns are currentGeneration.config.controlnets.keys() */}
            {/* each cell contains a slider and update the config */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Weight</TableCell>
                            <TableCell align="right">Start</TableCell>
                            <TableCell align="right">Stop</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(currentGeneration.config.controlnet_units).map((unit_name) => (
                            <TableRow
                                key={unit_name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>{unit_name}</b>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField type='number' value={currentGeneration.config.controlnet_units[unit_name].weight} onChange={(e) => {
                                        setCurrentGeneration(prevState => ({
                                            ...prevState,
                                            config: {
                                                ...prevState.config,
                                                controlnet_units: {
                                                    ...prevState.config.controlnet_units,
                                                    [unit_name]: {
                                                        ...prevState.config.controlnet_units[unit_name],
                                                        weight: e.target.value
                                                    }
                                                }
                                            }
                                        }));
                                    }}
                                        inputProps={{
                                            min: 0,
                                            max: 1,
                                            step: 0.01,
                                        }}
                                    />
                                </TableCell>

                                <TableCell align="right">
                                    <TextField type='number' value={currentGeneration.config.controlnet_units[unit_name].start} onChange={(e) => {
                                        setCurrentGeneration(prevState => ({
                                            ...prevState,
                                            config: {
                                                ...prevState.config,
                                                controlnet_units: {
                                                    ...prevState.config.controlnet_units,
                                                    [unit_name]: {
                                                        ...prevState.config.controlnet_units[unit_name],
                                                        start: e.target.value
                                                    }
                                                }
                                            }
                                        }));
                                    }}
                                        inputProps={{
                                            min: 0,
                                            max: 1,
                                            step: 0.01,
                                        }}

                                    />
                                </TableCell>

                                <TableCell align="right">
                                    <TextField type='number' value={currentGeneration.config.controlnet_units[unit_name].end} onChange={(e) => {
                                        setCurrentGeneration(prevState => ({
                                            ...prevState,
                                            config: {
                                                ...prevState.config,
                                                controlnet_units: {
                                                    ...prevState.config.controlnet_units,
                                                    [unit_name]: {
                                                        ...prevState.config.controlnet_units[unit_name],
                                                        end: e.target.value
                                                    }
                                                }
                                            }
                                        }));
                                    }}
                                        inputProps={{
                                            min: 0,
                                            max: 1,
                                            step: 0.01,
                                        }}

                                    />
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



        </div >
    );
};

export default ControlnetsConfigPanel;
