import { useState, useEffect, useContext } from 'react';
import { Container, Card, Grid, CardContent, Typography, Box, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { getItemsFromArchives, votePoem } from '../firebase/firebaseCalls';
import { UserContext, UserDataContext, VoteContext } from '../App';
import { ArrowDownward, ArrowUpward, ThumbDown, ThumbUp } from '@mui/icons-material';
import { GeneratedItem, PoemItem } from '../components/GeneratedItem';
import { analytics } from '../firebase/firebase';
import { logEvent } from 'firebase/analytics';

const ArchivesPage = () => {
    const { user, setUser } = useContext(UserContext)
    const { userData, setUserData } = useContext(UserDataContext)
    const votes = userData.votes || {};
    const [items, setItems] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    //analytics
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Archives',
            page_location: window.location.href,
            page_path: '/archives',
        });
    }, []);




    useEffect(() => {
        loadMore();
    }, []);

    const loadMore = async () => {
        const newItems = await getItemsFromArchives(lastVisible);
        setItems([...items, ...newItems.archives]);
        setLastVisible(newItems.lastVisible);
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            {/* grey background with round corners */}
            <Grid container spacing={2}
                style={{
                }}
            >
                {items.map((item, index) => (
                    <Grid item xs={12} md={12} alignItems="center">
                        <GeneratedItem item={item}
                            key={index}
                            userVote={
                                item.id in votes ? votes[item.id] : null
                            }
                            displayVotes={true}
                        />
                    </Grid>
                ))}

            </Grid>
            <Box display="flex" justifyContent="center" marginTop={4}>
                <Button variant="outlined" color="primary" onClick={loadMore}>
                    Load More
                </Button>
            </Box>
        </Container>
    );
};

export default ArchivesPage;
