import React, { useContext, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Slider, Grid } from '@mui/material';
import { CurrentGenerationContext } from '../App';


const QrcodeConfigPanel = () => {
    const { currentGeneration, setCurrentGeneration } = useContext(CurrentGenerationContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                qrcode: {
                    ...prevState.config.qrcode,
                    [name]: value
                }
            }
        }));

    };

    const handleSliderChange = (event, newValue) => {
        setCurrentGeneration({
            ...currentGeneration,
            config: {
                ...currentGeneration.config,
                global: {
                    ...currentGeneration.config.global,
                    [event.target.name]: newValue
                }
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                global: globalConfig
            }
        }));
    };
    console.log(currentGeneration.config.global.mode)
    return (
        <div>

            <br />

            {/* error correction select [low, medium, quart, high] currentGeneration.config.qrcode.error_correction */}            {/* error correction select [low, medium, quart, high] currentGeneration.config.qrcode.error_correction */}
            {/* box size textfield number currentGeneration.config.qrcode.box_size */}
            {/* border textfield number currentGeneration.config.qrcode.border */}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel><b>Error Correction</b></InputLabel>
                        <Select
                            label="Error Correction"
                            name="error_correction"
                            value={currentGeneration.config.qrcode.error_correction}
                            onChange={handleChange}
                        >
                            <MenuItem value={'low'}>Low (7%) </MenuItem>
                            <MenuItem value={'medium'}>Medium (15%)</MenuItem>
                            <MenuItem value={'quart'}>Quartile (25%)</MenuItem>
                            <MenuItem value={'high'}>High (30%)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Box Size"
                        name="box_size"
                        type="number"
                        value={currentGeneration.config.qrcode.box_size}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Border Size"
                        name="border"
                        type="number"
                        value={currentGeneration.config.qrcode.border}
                        onChange={handleChange}
                    />
                </Grid>


            </Grid>



        </div >
    );
};

export default QrcodeConfigPanel;