import React, { useContext, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Slider, Grid } from '@mui/material';
import { CurrentGenerationContext } from '../App';


const GlobalConfigPanel = ({ availableConfigsAndModels }) => {
    const { currentGeneration, setCurrentGeneration } = useContext(CurrentGenerationContext);

    const schedulers = ['DDIM', 'Euler', 'Euler a', 'LMS', 'DPM2 Karras', 'DPM2 a Karras', 'Heun', 'DDPM', 'UniPC', 'PNDM', 'DEI', 'DPM++ SDE', 'DPM++ 2S a', 'DPM++ 2M', 'DPM++ SDE Karras', 'DPM++ 2S a Karras', 'DPM++ 2M Karras'];
    const sd_models = availableConfigsAndModels.sd_models;
    const modes = ['txt2img'];
    const sizes = [512, 640, 768, 896, 1024];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                global: {
                    ...prevState.config.global,
                    [name]: value
                }
            }
        }));

    };

    const handleSliderChange = (event, newValue) => {
        setCurrentGeneration({
            ...currentGeneration,
            config: {
                ...currentGeneration.config,
                global: {
                    ...currentGeneration.config.global,
                    [event.target.name]: newValue
                }
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setCurrentGeneration(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                global: globalConfig
            }
        }));
    };
    console.log(currentGeneration.config.global)
    return (
        <div>
            <br />
            {/* mode select */}
            <Grid container spacing={2}>
                <Grid item sm={2} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel><b>Mode</b></InputLabel>
                        <Select label={"Mode"} name="mode" defaultValue={"txt2img"} value={currentGeneration.config.global.mode} onChange={handleChange}>
                            {modes.map((mode, index) => <MenuItem key={mode} value={mode}>{mode}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                {/* sd model select */}
                <Grid item sm={5} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel><b>SD Model</b></InputLabel>
                        <Select label="SD Model" name="model_name_or_path" value={currentGeneration.config.global.model_name_or_path} onChange={handleChange}
                        >
                            {sd_models.map((sd_model, index) => <MenuItem key={index} value={sd_model}>{sd_model}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel><b>Scheduler</b></InputLabel>
                        <Select fullWidth label="Scheduler" name="scheduler_name" value={currentGeneration.config.global.scheduler_name} onChange={handleChange}>
                            {schedulers.map((scheduler, index) => <MenuItem key={index} value={scheduler}>{scheduler}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sm={5} xs={12}>
                    <TextField fullWidth label="Negative Prompt" name="negative_prompt" value={currentGeneration.config.global.negative_prompt} onChange={handleChange}
                        InputLabelProps={{
                            sx: {
                                fontWeight: 'bold'
                            }
                        }}
                    />
                </Grid>

                {/* Dont display if xs */}
                <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }} > <TextField fullWidth sx={{ visibility: 'hidden' }} /> </Grid>

                <Grid item sm={1.5} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel><b>Width</b></InputLabel>
                        <Select fullWidth label="Width" name="width" value={currentGeneration.config.global.width} onChange={handleChange}>
                            {sizes.map((size, index) => <MenuItem key={index} value={size}>{size}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={1.5} xs={6} >
                    <FormControl fullWidth>
                        <InputLabel><b>Height</b></InputLabel>
                        <Select label="Height" name="height" value={currentGeneration.config.global.height} onChange={handleChange}>
                            {sizes.map((size, index) => <MenuItem key={index} value={size}>{size}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }} > <TextField fullWidth sx={{ visibility: 'hidden' }} /> </Grid>

                <Grid item sm={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Seed" type="number" name="seed" value={currentGeneration.config.global.seed} onChange={handleChange}
                            InputLabelProps={{
                                sx: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={6} xs={6}>
                    <InputLabel sx={{ fontSize: '1rem' }}>
                        <b>Cfg Scale:</b> {currentGeneration.config.global.cfg_scale}
                    </InputLabel>
                    <Slider value={currentGeneration.config.global.cfg_scale} onChange={handleSliderChange} name="cfg_scale" min={0} max={30} />
                </Grid>
                <Grid item sm={6} xs={6}>
                    <InputLabel sx={{ fontSize: '1rem' }}>
                        <b>Steps:</b> {currentGeneration.config.global.steps}
                    </InputLabel>

                    <Slider
                        value={currentGeneration.config.global.steps}
                        onChange={handleSliderChange}
                        name="steps"
                        min={0} max={100}
                        valueLabelDisplay="auto"


                    />
                </Grid>


            </Grid>
        </div >
    );
};

export default GlobalConfigPanel;
