// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBdjCODfofULPPtIifFy6isQ-iQzgr2zT8",
    authDomain: "qr-art-41f77.firebaseapp.com",
    projectId: "qr-art-41f77",
    storageBucket: "qr-art-41f77.appspot.com",
    messagingSenderId: "998799705452",
    appId: "1:998799705452:web:3d6bc2fa38980796bb5f49",
    measurementId: "G-RJ6Y3E9MPK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const functions = getFunctions(app)
if (process.env.REACT_APP_PROD === "false") {
    console.log('connecting to functions emulator')
    connectFunctionsEmulator(functions, "localhost", 5001);
}
const db = getFirestore(app);

export { app, auth, db, functions, analytics };